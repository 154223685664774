.LoginPage_mainDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem 0;
}

.loginPageStyle {
  /* padding: 3rem;  */
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.loginPageDiv {
  padding: 6rem 3rem !important;
}

.inputForm {
  text-align: center;
}

.title_head {
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 2rem;
}

.inputDiv {
  background-color: #e864583d;
  padding: 0.2rem;
  margin-bottom: 1rem;
  border-radius: 20px;
  display: flex;
  align-items: center;
}

.inputField {
  flex: 1;
  outline: none;
  width: 100%;
  border: none;
  background: none !important;
  padding: 0.5rem 1rem;
  margin: 0rem 0.5rem;
}

.inputField.autofilled {
  background-color: transparent !important;
}

.inputBtn {
  margin-top: 1rem;
  border-radius: 20px;
  background-color: #e86458d7 !important;
  color: white !important;
  border: none !important;
  padding: 10px 50px;
  font-weight: 500;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.inputBtn:hover {
  border: 2px solid #e86458 !important;
  color: #e86458 !important;
  background-color: #fff !important;
}

.eyeIcon {
  cursor: pointer;
  padding: 10px 20px;
  color: gray;
}

.reg-section {
  background-color: #e86458;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 2rem !important;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}

.reg-section h3 {
  margin-bottom: 1rem;
}

.reg-section .inputBtn {
  background-color: #fff !important;
  color: #e86458 !important;
}

.reg_style {
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}


input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: #000;
  -webkit-box-shadow: 0 0 0px 1000px #00000000 inset;
  transition: background-color 5000s ease-in-out 0s;
}