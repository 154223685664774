@tailwind base;
@tailwind components;
@tailwind utilities;

h5{
  margin: 0 !important;
  margin-left: 1rem !important;
  text-transform: uppercase !important;
}


/* Loader */
.loader {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20%;
  width: 80px;
  height: 80px;
  padding-left: 0;
}
.loader:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #fa9c23;
  border-color: #fa9c23 transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* //navbar style */

.NavBar_styles {
  box-sizing: border-box;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.nav-link{
  padding-right: 20px !important;
  font-weight: 600 !important;
  /* color: #e86458 !important; */
}

.dropDownVariant {
  background-color: yellow !important; 
}

#dropdown-basic{
  border: none;
  background: #e86458 !important;
  border-radius: 20px;
  padding: 5px 15px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.1ch;
}


.footer_set{
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}


@media print {
  
  @page {
    margin-top: 0.5cm;
    margin-bottom: 0.5cm;
  }
  .url {
    display: none !important;
  }
}


.print-title {
  display: none;
}

/* Show title only in print mode */
@media print {
  .print-title {
    display: block;
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  .no-print {
    display: none; /* Hides elements with class 'no-print' */
  }
}