.form-label {
  margin-bottom: 3px !important;
  font-weight: 500;
  font-size: 13px;
}

.col {
  margin: 4px 0;
}

.form-check {
  font-size: 15px;
  font-weight: 500;
  margin: 0 20px;
  font-size: 20px;
}

.checkbox-reverse {
  display: flex;
  flex-direction: row-reverse;
}

.form_div {
  margin: 4rem 0rem;
  box-sizing: border-box;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  /* background-color: #faf9f694; */
}